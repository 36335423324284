<template>
  <en-dialog :model-value="modelValue" width="80%" title="应付对账单" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-ajax link :method="operation.export.click">导出</button-ajax>
    </template>
    <form-query :model="form.data" :method="table.get">
      <en-form-item label="付款搜索">
        <en-input v-model="form.data.quickSearch" placeholder="付款对象/单号"></en-input>
      </en-form-item>
    </form-query>
    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['PABSTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>
    <table-dynamic
      :height="600"
      code="PYBE"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    >
      <template #DOCUMENT_COMMENT="{ row }: { row: EnocloudSettlementDefinitions['PayableQueryDto'] }">
        {{ row.comment }}
      </template>
      <template #SERIAL_NO="{ row }: { row: EnocloudSettlementDefinitions['PayableQueryDto'] }">
        <en-button type="primary" link @click="table.serialNo.click(row)" :disabled="row.type?.code !== 'PUC'">{{ row.serialNo }} </en-button>
      </template>
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      tabs: {
        active: ''
      },
      operation: {
        children: {
          export: {
            async click() {
              this.store.openDownload()
              return this.ajax('GET /enocloud/settlement/payable/export', this.table.$ajaxParams)
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      form: {
        data: {
          branchIds: [],
          payeeId: '',
          quickSearch: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/payable/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active, ...this.form.data }
            }
          }
        },
        config: {
          OPERATION: { visible: false },
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          DOCUMENT_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['documentStartDate', 'documentEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'typeCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['PABTYPE']
                    }
                  },
                  allowCreate: true,
                  remote: true,
                  multiple: true,
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          RECEIPT_COMMENT: { header: { filter: { type: 'text', field: 'receiptComment' } } },
          CELLPHONE: { header: { filter: { type: 'text', field: 'cellphone' } } },
          INVOICE_NO: { header: { filter: { type: 'text', field: 'invoiceNo' } } },
          INVOICE_TITLE: { header: { filter: { type: 'text', field: 'invoiceTitle' } } },
          INVOICE_DATE: {
            header: { filter: { type: 'date', field: ['invoiceDateStart', 'invoiceDateEnd'], props: { type: 'daterange' } } }
          },
          RECEIPT_DATETIME: {
            header: { filter: { type: 'date', field: ['businessStartDate', 'businessEndDate'], props: { type: 'daterange' } } }
          },
          PREPARED_BY: { header: { filter: { type: 'text', field: 'preparedByName' } } },
          CREDIT_BY: { header: { filter: { type: 'text', field: 'creditBy' } } },
          SERVICE_SETTLEMENT_DATETIME: {
            header: { filter: { type: 'date', field: ['settlementStartDate', 'settlementEndDate'], props: { type: 'daterange' } } }
          },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } },
          RECEIPT_PAYMENT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'receiptPaymentMethod',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['PAMTMTD'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          DOCUMENT_COMMENT: { header: { filter: { type: 'text', field: 'comment' } } },
          SETTLED_DATETIME: {
            header: { filter: { type: 'date', field: ['settledStartDate', 'settledEndDate'], props: { type: 'daterange' } } }
          },
          INVOICE_IMAGE: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiceImg',
                props: {
                  options: [
                    { message: '有', code: 'Y' },
                    { message: '无', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          RECEIPT_BYS: {
            header: {
              filter: {
                type: 'select',
                field: 'receiptBy',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: '' }
                }
              }
            }
          }
        },
        children: {
          serialNo: {
            async click(row: EnocloudSettlementDefinitions['PayableQueryDto']) {
              switch (row.type!.code) {
                case 'PUC':
                case 'OTH':
                  this.router.push('/accessory/stock/in/purchase', (vm) => {
                    vm.form.data.id = row.documentId
                    vm.form.get()
                  })
                  break
                case 'POLRBT':
                case 'SVC':
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
